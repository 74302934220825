@baseFontSize: 75; //基于视觉稿横屏尺寸/10得出的基准font-size
.px2rem(@name, @dp) {
    @{name}: @dp*416*2/160 / @baseFontSize * 1rem;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, em, p, font, img, small, strong, center, dl, dt, dd, ol, ul, li, form, label, table, caption, tbody, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 12px;
  vertical-align: baseline;
  font-family: '微软雅黑';
}

;

ol, ul {
  list-style: none
}

:focus {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

caption, th, td {
  text-align: left;
  font-weight: normal
}

strong {
  font-weight: 600
}

a {
  font: 12px Arial;
  text-decoration: none;
  color: #404040;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
  color: #FF3300
}

h1 {
  font-size: 16px;
  font-weight: 600;
  color: #666
}

@red: #de2941;
@grey: #585858;
@textBlack: #282828;
@textGrey: #656565;
@textRed: #e75446;
@textWhite: #ffffff;
@borderGrey: #e2e2e2;
@backgroundGrey: whitesmoke;
@backgroundWhite: #fff;
.borderTopNormal {
  border-top: 1px @backgroundGrey solid;
}

.borderBottomNormal {
  border-bottom: 1px @backgroundGrey solid;
}

.overflow1 {
  padding: 0 2px;
  .px2rem(height, 40);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bigBottom {
  border-bottom: @backgroundGrey solid;
  .px2rem(border-bottom-width, 30);
}

.numberBig {
  .px2rem(font-size, 20);
}

.numberSmall {
  .px2rem(font-size, 18);
}

.textBig {
  .px2rem(font-size, 18);
}

.textNormal {
  .px2rem(font-size, 17);
}

.textSmall {
  .px2rem(font-size, 16);
}

.textMini {
  .px2rem(font-size, 12);
}

.a {
  border: 1px red solid;
}

.b {
  border: 1px black solid;
}

::-webkit-scrollbar {
  .px2rem(width, 0);
  .px2rem(height, 0);
  background-color: #F5F5F5;
}

///*定义滚动条轨道 内阴影+圆角*/
//::-webkit-scrollbar-track
//{
//  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
//  border-radius: 10px;
//  background-color: #F5F5F5;
//}
//
///*定义滑块 内阴影+圆角*/
//::-webkit-scrollbar-thumb
//{
//  border-radius: 10px;
//  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
//  background-color: #555;
//}
.headerImg {
  .px2rem(width, 85);
  .px2rem(height, 85);
  border-radius: 100%;
  display: block;
  margin: 0 auto;
}

.backMain {
  .px2rem(margin-top, 35);
  .px2rem(margin-bottom, 36);
  i {
    display: block;
    background: url("../images/back1.png");
    background-size: cover;
    float: right;
    .px2rem(width, 15);
    .px2rem(height, 15);
  }
}

.nList {
  .item1 {
    box-sizing: border-box;
    .px2rem(margin-top, 30);
    padding: 0;
    .px2rem(padding-left, 13);
    width: 100%;
    .px2rem(height, 50);
    border: 1px @borderGrey solid;
    .px2rem(border-radius, 4);
    input {
      .px2rem(height, 40);
      height: 100%;
    }
  }
  .forget {
    .px2rem(margin-top, 20);
    .px2rem(margin-bottom, 20);
    text-align: right;
    color: @textGrey;
  }
  .btn1 {
    width: 100%;
    border: none;
    background: url("../images/2.png");
    background-size: cover;
    .px2rem(height, 50);
    .px2rem(margin-top, 30);
    .px2rem(border-radius, 4);
    .px2rem(line-height, 50) !important;
    .px2rem(font-size, 20);
    //.px2rem(letter-spacing,20);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: @textWhite;
    //text-align: center;
  }
}

.left {
  float: left
}

.right {
  float: right
}

.clearfix:before,
.clearfix:after {

  display: table;

  line-height: 0;

  content: "";

}

.clearfix:after {

  clear: both;

}

.clearfix {
  *zoom: 1;
}

.send {
  float: right;
  display: block;
  position: absolute;
  .px2rem('right', 2);
  .px2rem('top', 6);
  .px2rem('font-size', 13);
  border: 1px @red solid;
  .px2rem('width', 95);
  .px2rem('height', 40);
  .px2rem('line-height', 40);
  .px2rem('border-radius', 19);
  color: @red;
  text-align: center;
}

//弹出框
.alertMsg1 {
  width: 100vw;
  display: block;
  z-index: 100;
  text-align: center !important;
  position: fixed;
  top: 60%;
  left: 0;
  span {
    margin: 0 auto;
    color: @textWhite;
    background: #565656;
    .px2rem(padding-top, 6);
    .px2rem(padding-bottom, 5);
    .px2rem(padding-left, 10);
    .px2rem(padding-right, 10);
    .px2rem(border-radius, 4);
    .px2rem(font-size, 16) !important;
  }
  //left: (50% - self);
}

//选择规格框
.showD {
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  > div {
    z-index: 6;
    position: absolute;
    bottom: 0;
    left: 0;
    .px2rem(height, 562);
    width: 100vw;
    background: @textWhite;
    > .close {
      position: absolute;
      .px2rem(top, 15);
      .px2rem(right, 16);
      display: block;
      .px2rem(width, 30);
      .px2rem(height, 30);
      background: url("../images/close1.png");
      background-size: cover;
    }
    .product {
      .px2rem(padding, 15);
      .borderBottomNormal;
      display: table;
      > img {
        .px2rem(height, 105);
        .px2rem(width, 105);
        vertical-align: text-top;
      }
      .pro {
        border: 1px white solid;
        display: table-cell;
        width: 100%;
        .px2rem(padding-left, 15);
        .price {
          .px2rem(line-height, 35);
          color: @textRed;
          .px2rem(font-size, 24);
        }
        > p {
          .px2rem(font-size, 18);
          .px2rem(line-height, 26);
        }
      }
    }
    .class {
      .px2rem(font-size, 20);
      .px2rem(padding-left, 15);
      .px2rem(margin-top, 10);
      .px2rem(height,260);
      overflow-y: scroll;
      > div {
        span {
          float: left;
          display: block;
          .px2rem(margin-left, 20);
          .px2rem(margin-top, 10) !important;
          .px2rem(padding-left, 10);
          .px2rem(padding-right, 10);
          .textSmall;
          .px2rem(line-height, 16);
          border: 1px @borderGrey solid;
          .px2rem(border-radius, 4);
          .px2rem(padding-top, 6);
          .px2rem(padding-bottom, 5);
        }
        span.hover {
          border: 1px @textRed solid;
          background: @textRed;
          color: #fff;
        }
      }
    }
    .num {
      .borderTopNormal;
      .px2rem(padding-top, 10);
      position: absolute;
      .px2rem(bottom, 59);
      left: 0;
      width: 100vw;
      z-index:6;
      .num2 {
        position: absolute;
        bottom: 0;
        .px2rem(font-size, 20);
        .px2rem(height, 40);
        .px2rem(line-height, 40);
        .px2rem(padding-left, 15);
      }
      .num1 {
        vertical-align: text-bottom;
        float: right;
        .px2rem(padding-right, 15);
        span,input {
          border: 1px transparent solid;
          display: inline-block;
          .px2rem(font-size, 25);
          background: #eeeeee;
          background-size: cover;
          .px2rem(width, 100);
          .px2rem(height, 40);
          text-align: center;
          .px2rem(border-radius, 5);
          vertical-align: bottom;
        }
      }
      i {
        display: inline-block;
        vertical-align: bottom;
        .px2rem(width, 40);
        .px2rem(height, 40);
      }
      i:first-child {
        background: url("../images/num1.png");
        background-size: cover;
      }
      i:last-child {
        background: url("../images/num2.png");
        background-size: cover;
      }
    }
    .sure {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      .px2rem(height, 49);
      .px2rem(line-height, 50);
      .px2rem(font-size, 22);
      text-align: center;
      color: @textWhite;
      background: url("../images/chartTop.png");
      background-size: cover;
    }
  }
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none !important;
  color: black !important;
}

//页面头部
.header {
  position: relative;
  background: url("../images/chartTop.png");
  background-size: cover;
  border: 1px transparent solid;
  width: 100%;
  .px2rem(height, 64);
  .px2rem(font-size, 26);
  span {
    .px2rem(font-size, 20);
    .px2rem(line-height, 64);
    color: #fff !important;
    display: block;
    position: absolute;
    .px2rem(right, 15);
    top: 0;
  }
}

.header h1 {
  font-weight: normal;
  .px2rem(font-size, 20);
  .px2rem(line-height, 64);
  color: #fff !important;
  display: block;
  text-align: center;
}

.header a.back {
  position: absolute;
  left: 0;
  .px2rem(height, 64);
  .px2rem(padding-right, 30);
  .px2rem(padding-top, 17);
  .px2rem(padding-left, 10);
  i {
    display: block;
    background: url("../images/icon-back.png") no-repeat;
    background-size: cover;
    .px2rem(height, 26);
    .px2rem(width, 15);
  }
}

//登录等页面
.mainList {
  background: url("../images/loginBg.jpg");
  height: 100vh;
  border: 1px transparent solid;
  .px2rem(padding-left, 50);
  .px2rem(padding-right, 50);
  .loginTop {
    .px2rem(height, 120);
    .px2rem(width, 120);
    .px2rem(margin-top, 150);
    margin-left: auto;
    margin-right: auto;
    background: url("../images/loginTop.png") no-repeat center;
    background-size: cover;
  }
  .inputList {
    .px2rem(margin-top, 100);
    > button {
      width: 100%;
      .px2rem(height, 60);
      .px2rem(border-radius, 6);
      .px2rem(letter-spacing, 16);
      .px2rem(margin-top, 20);
      background: url("../images/loginBtn.jpg");
      background-size: cover;
      border: none;
      .px2rem(font-size, 24);
      color: @textWhite;
    }
  ;
    > button.disabledBtn {
      background: url("../images/loginBtn1.png");
      cursor: not-allowed;
    }
    .sayPs {
      color: #989898;
      .textNormal;
      .px2rem(margin-top, 20);
    }
    > p {
      position: relative;
      .px2rem(padding-left, 50);
      .px2rem(padding-bottom, 10);
      .px2rem(padding-top, 15);
      > input {
        background: transparent;
        .px2rem(font-size, 20);
        .px2rem(height, 30);
        width: 100%;
        color: @textBlack;
      }
      > span {
        position: absolute;
        .px2rem(line-height, 60);
        .px2rem(padding-left, 20);
        .px2rem(padding-right, 8);
        right: 0;
        top: 0;
        color: #de4154;
        .textBig;
      }
      > i {
        position: absolute;
        .px2rem(line-height, 60);
        .px2rem(padding-left, 20);
        .px2rem(width, 80);
        right: 0;
        top: 0;
        color: #de4154;
        .textBig;
      }
      > span:after, > i:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: -webkit-linear-gradient(transparent, #de4154, transparent);
        background: -moz-linear-gradient(transparent, #de4154, transparent);
        background: linear-gradient(transparent, #de4154, transparent);
      }
    }
    > p:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      //width: 1px;
      height: 1px;
      background: -webkit-linear-gradient(left, transparent, #de4154, #de4154, #de4154, transparent);
      background: -moz-linear-gradient(left, transparent, #de4154, #de4154, #de4154, transparent);
      background: linear-gradient(left, transparent, #de4154, #de4154, #de4154, transparent);
    }
    .bottomText {
      border-top: 1px @borderGrey solid;
      .px2rem(padding-top, 30);
      .px2rem(margin-top, 50);
      color: #6e6e6e;
      text-align: center;
      .textNormal;
    }
  }
}

//页脚
#footer {
  position: fixed;
  bottom: 0;
  background: @backgroundWhite;
  border-top: 1px @borderGrey solid;
  li {
    float: left;
    height: 60px;
    .px2rem(padding-top, 10);
    .textBig;
    width: 33.3vw;
    text-align: center;
    > img {
      display: block;
      margin: 0 auto;
      .px2rem(height, 30);
    }
    > img.hover {
      display: none;
    }
  }
  li.active {
    color: @textRed;
    img {
      display: none;
    }
    img.hover {
      display: block;
    }
    //background: url("../images/footerShop.png") no-repeat center;
  }
}

//登录页面
#login {
  .mainList;
}

//密码登录
#passwordLogin {
  .mainList;
  .inputList > button {
    .px2rem(margin-top, 40);
  }
  .sayPs {
    .textBig;
    text-align: center;
    color: #616060;
  }
}

//忘记密码登录
#resetpassword {
  .mainList;
  .inputList > button {
    .px2rem(margin-top, 40);
    letter-spacing: normal;
  }
}

//新建密码
#setPassword {
  .mainList;
  .inputList > button {
    .px2rem(margin-top, 40);
  }
}

//首页
#main {
  width: 100vw;
  height: 100vh;
  background: #eeeeee;
  .topImg {
    .px2rem(height, 135);
    background: url("../images/shopbg.jpg");
    background-size: cover;
  }
  .mainBox {
    //border:1px red solid;
    box-shadow: 1px 1px 2px 1px rgb(164, 164, 164);
    background: #fff;
    .px2rem(margin-top, 50);
    .px2rem(margin-left, 50);
    .px2rem(margin-right, 50);
    .px2rem(padding-top, 30);
    .px2rem(padding-left, 25);
    .px2rem(padding-right, 25);
    .px2rem(border-radius, 6);
    > p {
      .px2rem(font-size, 15);
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    .shopMsg {
      border-top: 1px @borderGrey solid;
      .px2rem(margin-top, 20);
      .px2rem(padding-left, 50);
      .px2rem(padding-right, 50);
      .px2rem(padding-bottom, 50);
      .center {
        text-align: center;
      }
      > img {
        display: block;
        .px2rem(height, 65);
        .px2rem(width, 65);
        border-radius: 100%;
        margin: 0 auto;
        .px2rem(margin-top, 20);
        .px2rem(margin-bottom, 20);
      }
      > span {
        text-align: center;
        display: block;
        .px2rem(font-size, 16);
      }
      > p {
        .px2rem(line-height, 25);
        .px2rem(font-size, 14);
      }
      .mainBtn {
        //.px2rem(height,20);
        .px2rem(line-height, 50);
        .px2rem(border-radius, 6);
        border: 1px #eee solid;
        .px2rem(margin-top, 20);
        text-align: center;
        .px2rem(font-size, 20);
        //.px2rem(padding-top,12);
        //.px2rem(padding-bottom,18);
      }
    }

  }
  /*  border: 1px transparent solid;
    background: url("../images/mainbg.png");
    height: 100vh;
    width: 100%;
    .topImg{
      .px2rem(margin-top,220);
      img{
        .px2rem(height,120);
        display: block;
        margin: 0 auto;
      }
    }
    .text{
      text-align: center;
      color: @textWhite;
      .px2rem(font-size,22);
      .px2rem(margin-top,93);
    }
    .btns{
      width: 100vw;
      background: url("../images/mainBtn.png");
      position: fixed;
      bottom: 0;
      left: 0;
      button{
        width: 50%;
        .px2rem(height,60);
        border: none;
        .px2rem(font-size,22);
      }
      button:last-child{
        border-left: 1px @borderGrey solid;
      }
    }*/
}

//未登录时的页面
#getLogin {
  .topImg {
    .px2rem(height, 135);
    background: url("../images/shopbg.jpg");
    background-size: cover;
    > img {
      .px2rem(height, 65);
      .px2rem(width, 65);
      border-radius: 100%;
      .px2rem(margin-left, 30);
      .px2rem(margin-top, 35);
    }
  }
  .mainText {
    .px2rem(margin-left, 45);
    .px2rem(margin-right, 45);
    .px2rem(margin-top, 85);
    > p {
      border-bottom: 1px @borderGrey solid;
      position: relative;
      .px2rem(padding-top, 15);
      .px2rem(padding-bottom, 10);
      input {
        width: 100%;
        .textBig;
      }
      span, i {
        position: absolute;
        border-left: 1px @textRed solid;
        right: 0;
        .px2rem(bottom, 10);
        .px2rem(line-height, 40);
        .px2rem(padding-left, 20);
        .px2rem(padding-right, 10);
        .px2rem(height, 40);
        .textBig;
        color: @textRed;
      }
      i {
        .px2rem(width, 121);
        text-align: center;
      }
    }
    .loginBtn {
      .px2rem(height, 50);
      .px2rem(border-radius, 6);
      .px2rem(margin-top, 65);
      text-align: center;
      .px2rem(line-height, 50);
      .px2rem(font-size, 20);
      background: #e75446;
      color: @textWhite;
    }
    .loginBtn.disable {
      background: rgba(245, 83, 69, 0.71);
    }
  }
}

//店铺列表
#shopList {
  .shopList {
    li {
      display: table;
      border: 1px @borderGrey solid;
      background: @backgroundWhite;
      .px2rem(padding, 15);
      width: 100%;
      margin-top: -1px;
      > img {
        .px2rem(height, 60);
        .px2rem(width, 60);
        border-radius: 100%;
        .px2rem(vertical-align, -20);
      }
      > p {
        display: table-cell;
        width: 100%;
        .px2rem(line-height, 60);
        .textBig;
        .px2rem(padding-left, 15);
        position: relative;
        > span {
          color: @textRed;
          position: absolute;
          .px2rem(right, 10);
          .px2rem(font-size, 16);
        }
      }
    }
    overflow-y: auto;
    .px2rem(padding-bottom, 10);
  }
}

/*#login{
  .px2rem(padding-right,28);
  .px2rem(padding-left,28);
 .nList{
   .px2rem(margin-top,60);
 }
}*/
/*#resetpassword,#register{
  .px2rem(padding-right,28);
  .px2rem(padding-left,28);
  .get{
    position: relative;
    >span{
      position: absolute;
      .px2rem(line-height,40);
      .px2rem(font-size,18);
      .px2rem(right,10);
      top: 0;
    }
  }
}*/
a.back {
  display: block;
  font-size: 26px;
  position: absolute;
  color: #fff !important;
  i {
    .px2rem(width, 13);
    .px2rem(height, 22);
    .px2rem(line-height, 55);
  }
  .px2rem(left, 15);
  top: 0;
}

//未添加客户提醒
#addClient {
  height: 100%;
  position: relative;
  .topImg {
    .px2rem(padding, 60);
    .px2rem(margin-top, 50);
    .px2rem(padding-bottom, 0);
    > img {
      width: 100%;
    }
    > p {
      .textBig;
      .px2rem(font-size, 16);
    }
  }
  .clientTag {
    .px2rem(padding, 20);
    .px2rem(margin-top, 50);
    display: table;
    width: 100%;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4);
    > img {
      .px2rem(width, 80);
      .px2rem(height, 80);
      border: 1px @borderGrey solid;
      border-radius: 100%;
      vertical-align: text-top;
    }
    > div {
      width: 100%;
      display: table-cell;
      .px2rem(padding-left, 15);
      > p {
        .textBig;
      }
    }
  }
  .successfulBottom {
    box-sizing: border-box;
    .px2rem(padding, 15);
    //.px2rem(height,130);
    position: fixed;
    left: 0;
    width: 100vw;
    .px2rem(bottom, 0);
    background: rgba(0, 0, 0, 0.58);
    display: table;
    > img {
      .px2rem(width, 100);
      .px2rem(height, 100);
      vertical-align: text-top;
    }
    > p {
      display: table-cell;
      width: 100%;
      color: #fff;
      .textBig;
      .px2rem(padding-left, 10);
    }
  }
}

//选择企业
#chooseCompany {
  .companyList {
    background: @backgroundGrey;
    .px2rem(padding-top, 50);
    li {
      display: table;
      border: 1px @borderGrey solid;
      background: @backgroundWhite;
      .px2rem(padding, 15);
      width: 100%;
      margin-top: -1px;
      > img {
        .px2rem(height, 60);
        .px2rem(width, 60);
        border-radius: 100%;
        .px2rem(vertical-align, -20);
      }
      > p {
        display: table-cell;
        width: 100%;
        .px2rem(line-height, 60);
        .textBig;
        .px2rem(padding-left, 15);
      }
    }

  }
}

//店铺主页
#shopMain {
  .shopHead {
    a.back {
      background: rgba(0, 0, 0, 0.27);
      .px2rem(margin-top, 24);
      .px2rem(width, 35);
      .px2rem(height, 35);
      .px2rem(padding-left, 10);
      .px2rem(padding-top, 8);
      border-radius: 100%;
      i {
        display: block;
        border-left: 2px @backgroundWhite solid;
        border-top: 2px @backgroundWhite solid;
        .px2rem(width, 18);
        .px2rem(height, 18);
        transform: rotate(-45deg);
      }
    }
    box-sizing: border-box;
    color: #fff;
    .px2rem(padding-left, 15);
    .px2rem(padding-right, 15);
    background: url("../images/shopbg.jpg");
    background-size: cover;
    .px2rem(padding-top, 25.5);
    .search {
      width: 80%;
      margin: 0 auto;
      background: url("../images/shopsearch.png");
      background-size: cover;
      .px2rem(border-radius, 6);
      .px2rem(height, 40);
      opacity: 0.8;
      position: relative;
      i {
        display: block;
        background: url("../images/shopser1.png");
        background-size: cover;
        .px2rem(width, 20);
        .px2rem(height, 20);
        position: absolute;
        .px2rem(left, 10);
        .px2rem(top, 10);
      }
    }
    .shopIcon {
      display: table;
      img {
        .px2rem(width, 50);
        .px2rem(height, 50);
        .px2rem(margin-top, 16.5);
        .px2rem(margin-bottom, 10);
        .px2rem(border-radius, 8);
        vertical-align: middle;
      }
      span {
        display: table-cell;
        width: 100%;
        .px2rem(margin-left, 11);
        .px2rem(line-height, 76.5);
        .textNormal;
        //color: #282828;
        color: white;
      }
    }
  }
  .listTit {
    width: 100vw;
    span {
      .textNormal;
      .px2rem(height, 50);
      .px2rem(line-height, 50);
      display: inline-block;
      width: 33.333333333%;
      text-align: center;
      .borderBottomNormal;
      color: @textGrey;
    }
    span.active {
      color: @textRed;
      border-bottom: 2px @textRed solid;
    }
  }
}

//店铺内搜索
#goodsSearch {
  .header {
    h1 {
      margin: 0 auto;
      width: 70%;
      .px2rem(border-radius, 8);
      .px2rem(height, 50);
      .px2rem(margin-top, 7);
      background: white;
      background-size: cover;
      label {
        background: none;
        border: none;
      }
      i {
        background: url("../images/shopser1.png") no-repeat;
        background-size: cover;
        .px2rem(width, 24);
        .px2rem(height, 24);
        .px2rem(margin-right, 10);
        .px2rem(margin-left, -10);
      }
      input {
        display: table-cell;
        background-size: cover;
        font-weight: normal !important;
      }
    }
    a, span {
      .px2rem(font-size, 20);
    }
  }
}

//商品列表
#goodsList, #classGoods, #goodsSearch {
  .goodsList {
    overflow-y: scroll;
    background: @backgroundGrey;
    .px2rem(margin-bottom, 10);
    //.px2rem(padding-bottom,10);
  }
  overflow-y: scroll;
  overflow-x: hidden !important;
  .gochart {
    .px2rem(width, 42);
    .px2rem(height, 42);
    position: fixed;
    .px2rem(bottom, 50);
    .px2rem(right, 15);
    z-index: 10;
  }
  li {
    background: @textWhite;
    display: table;
    //.px2rem(margin-top,5);
    //.px2rem(padding-left,10);
    img.goodsImg {
      vertical-align: middle;
      display: inline-block;
      .px2rem(height, 125);
      .px2rem(width, 125);
    }
    div {
      .borderBottomNormal;
      width: 100%;
      position: relative;
      .px2rem(height, 125) !important;
      display: table-cell;
      .goodName {
        position: absolute;
        .px2rem(left, 15);
        .px2rem(top, 6);
        font-weight: bold;
        .textNormal;
      }
      p {
        position: absolute;
        .px2rem(left, 0);
        bottom: 0;
        width: 100%;
        .px2rem(height, 40);
        .px2rem(line-height, 40);
        .px2rem(padding-left, 15);
        span.price {
          .numberBig;
          color: @textRed;
        }
        .num {
          .px2rem(margin-left, 30);
          color: @textGrey;
        }
        img {
          float: right;
        }
      }
    }
  }
  .chart {
    .px2rem(width, 37);
    .px2rem(height, 37);
    vertical-align: text-top;
  }
}

.noGoods {
  margin-top: 50%;
  text-align: center;
  color: #989898;
  img {
    display: block;
    .px2rem(height, 170);
    margin: 0 auto;
  }
  p {
    .px2rem(margin-top, 20);
  }
}

//商品分类列表
#classList {
  background: @backgroundGrey;
  overflow-y: scroll;
}

#classList li {
  .px2rem(padding-left, 15);
  .px2rem(padding-right, 15);
  background: #fff;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: @textBlack;
    font-family: PingFang-SC-Medium;
    font-size: 1.248rem
  }
  .borderBottomNormal;
  .textBig;
  .px2rem(height, 50);
  .px2rem(line-height, 50);

  span {
    float: right;
    .px2rem(padding-top, 15);
    i {
      display: block;
      background: url("../images/classImg.png");
      background-size: cover;
      .px2rem(height, 20);
      .px2rem(width, 10);
    }
  }
}

//店铺简介
#shopIntro {
  background: @backgroundGrey;
  .shopIcon {
    .px2rem(padding-top, 20);
    img {
      vertical-align: text-top;
    }
  }
  .shopInfo1 {
    .px2rem(padding-top, 20);
    .px2rem(padding-bottom, 20);
    .px2rem(border-bottom-width, 30);
    .px2rem(margin-top, 2);
  }
  div {
    .px2rem(padding-top, 10);
    .px2rem(padding-bottom, 10);
    display: table;
    border-bottom: 1px @backgroundGrey solid;
    background: @backgroundWhite;
  }
  span {
    box-sizing: border-box;
    display: inline-block;
    .px2rem(width, 120);
    .textSmall;
    .px2rem(padding-right, 10);
    .px2rem(padding-left, 15);
    vertical-align: top;
    color: @textGrey;
  }
  p {
    width: 100%;
    display: table-cell;
    color: #282828;
    word-wrap: break-word;
    word-break: break-all;
    .textNormal;
    .px2rem(padding-right, 15);
  }
}

//进货单
#chart {
  /* .noGoods{
     margin-top: 40%;
     text-align: center;
     color: #989898;
     img{
       display: block;margin: 0 auto
     }
     p{
       .px2rem(margin-top,20);
     }
   }*/
  input[type="checkbox"] {
    -webkit-appearance: none;
    outline: none;
    border: none;
  }
  input.check {
    background: url("../images/icon_radio3.png") no-repeat center left;
    background-size: 20px 20px;
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -18px;
    width: 20px;
    height: 35px;
  }
  input.check:checked {
    background: url("../images/icon_radio4.png") no-repeat center left;
    background-size: 20px 20px;
  }
  input.goodsCheck:checked {
    background: url("../images/icon_radio4.png") no-repeat center left;
    background-size: 20px 20px;
  }
  input.check:checked {
    background: url("../images/icon_radio4.png") no-repeat center left;
    background-size: 20px 20px;
  }
  .checked {
    background: url("../images/icon_radio4.png") no-repeat left center;
    background-size: 20px 20px;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -18px;
    width: 20px;
    height: 35px;
  }
  .shopping1 {
    clear: both;
    overflow-x: hidden;
    overflow-y: scroll;
    background: @backgroundGrey;
  }
  .shop-group-item {
    .px2rem(margin-bottom, 10);
  }
  .shopping1 ul {
    border-bottom: 1px @backgroundGrey solid;
    background: @textWhite;
  }
  .shop-name {
    background: @textWhite;
    .borderBottomNormal;
    .textSmall;
    height: 35px;
    line-height: 35px;
    .px2rem(padding-left, 15);
    .px2rem(padding-right, 15);
    position: relative;
    color: @textBlack;
  }
  .shop-name h4 {
    a {
      .px2rem(font-size, 18);
    }
    float: left;
    background: url("../images/icon-kin.png") no-repeat left center;
    line-height: 35px;
    .px2rem(background-size, 20);
    padding-left: 25px;
    margin-left: 28px;
  }
  .shop-info {
    height: 120px;
    .px2rem(padding-left, 15);
    .px2rem(padding-right, 15);
    position: relative;
  }
  .shop-info .checkbox {
    background: url("../images/icon_radio3.png") no-repeat left center;
    background-size: 20px 20px;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -60px;
    width: 20px;
    height: 120px;
  }
  .shop-info .checkbox1 {
    background: url("../images/icon_radio4.png") no-repeat left center;
    background-size: 20px 20px;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -60px;
    width: 20px;
    height: 120px;
  }
  .shop-info .shop-info-img {
    position: absolute;
    top: 15px;
    left: 45px;
    .px2rem(width, 85);
    .px2rem(height, 85);
  }
  .shop-info .shop-info-img img {
    width: 100%;
    height: 100%;
  }
  .shop-info .shop-info-text {
    margin-left: 130px;
    padding: 15px 0;
  }
  .shop-info .shop-info-text h4 {
    font-weight: normal;
    color: @textBlack;
    .px2rem(font-size, 16);
    .px2rem(line-height, 20);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .shop-info .shop-info-text .shop-brief {
    height: 25px;
    line-height: 25px;
    .px2rem(font-size, 14);
    color: @textGrey;
    white-space: nowrap;
  }
  .shop-info .shop-info-text .shop-brief span {
    display: inline-block;
    margin-right: 8px;
  }
  .shop-info .shop-info-text .shop-price {
    height: 24px;
    line-height: 24px;
    position: relative;
  }
  .shop-info .shop-info-text .shop-price .shop-pices {
    color: @textRed;
    font-size: 16px;
  }
  .shop-info .shop-info-text .shop-arithmetic {
    position: absolute;
    .px2rem(right, 15);
    top: 0;
    width: 86px;
    box-sizing: border-box;
    white-space: nowrap;
    height: 100%;
    border: 1px solid @backgroundGrey;
  }
  .shop-info .shop-info-text .noBorder {
    border: none;
    .numberSmall;
    color: @textGrey !important;
    text-align: right;
  }
  .shop-info .shop-info-text .shop-arithmetic a {
    display: inline-block;
    width: 23px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    font-size: 16px;
  }
  .shop-arithmetic .plus {
    border-left: 1px solid @backgroundGrey;
  }
  .shop-arithmetic .minus {
    border-right: 1px solid @backgroundGrey;
  }
  .shop-info .shop-info-text .shop-arithmetic .failed {
    color: #d1d1d1;
  }
  .shop-info .shop-info-text .shop-arithmetic .num {
    width: 32px;
    text-align: center;
    border: none;
    //border-left: 1px solid @backgroundGrey;
    //border-right: 1px solid #e0e0e0;
    display: inline-block;
    height: 100%;
    box-sizing: border-box;
    vertical-align: top;
    //margin: 0 -6px;
  }
  .shopPrice {
    display: none;
  }
  .payment-bar {
    .borderTopNormal;
    width: 100vw;
    height: 4.16rem;
    position: fixed;
    bottom: 0;
    background: #fff;
    .checkbox {
      background: url("../images/icon_radio3.png") no-repeat left center;
      background-size: 20px 20px;
      position: absolute;
      top: 50%;
      left: 15px;
      margin-top: -60px;
      width: 20px;
      height: 120px;
    }
    .checkbox1 {
      background: url("../images/icon_radio4.png") no-repeat left center;
      background-size: 20px 20px;
      position: absolute;
      top: 50%;
      left: 15px;
      margin-top: -60px;
      width: 20px;
      height: 120px;
    }
  }
  .payment-bar .all-checkbox {
    float: left;
    .px2rem(line-height, 47);
    padding-left: 40px;
    .px2rem(font-size, 20);
    position: relative;
    i {
      position: absolute;
      .px2rem(left, 15);
      .px2rem(top, 20);
    }
  }
  .payment-bar .totalPrice1 {
    background: @backgroundGrey;
    width: 100vw;
    .px2rem(height, 30);
    .px2rem(line-height, 30);
    .px2rem(top, -30);
    position: absolute;
    .textSmall;
    .px2rem(padding-left, 15);
    //.px2rem(padding-top,5);
    //.px2rem(padding-bottom,5);
    span {
      .numberSmall;
      color: @textRed;
    }
  }
  .payment-bar button {
    border: none;
    position: absolute;
    right: 0;
    height: 4.16rem;
    width: 30%;
    .px2rem(font-size, 20);
    text-align: center;
    color: #fff !important;
    background: url("../images/chartBtn.png");
  }
}

//确认订单
#confirmOrder {
  .userMsg {
    background: @textWhite;
    .borderBottomNormal;
    .px2rem(padding-left, 15);
    .px2rem(padding-right, 15);
    .ion-chevron-right {
      color: #c8c8c8 !important;
      .px2rem(font-size, 26);
    }
    p {
      .px2rem(line-height, 40);
      .textNormal;
      color: @textBlack;
      span {
        .textNormal;
        float: right;
        color: @textGrey;
      }
      .icon {
        .px2rem(font-size, 26);
      }
    }
  }
  .addAddress {
    .px2rem(height, 70);
    i:first-child {
      .px2rem(font-size, 24);
      color: #656565;
      .px2rem(margin-right, 8);
      font-weight: bold;
    }
    .borderBottomNormal;
    p {
      .px2rem(line-height, 70)
    }
  }
  .footer .copyright {
    height: 44px;
    line-height: 44px;
    text-align: center;
    color: #848689;
    font-size: 12px;
  }
  .shopping1 {
    background: @backgroundGrey;
    clear: both;
    overflow-y: scroll;
  }
  .shop-group-item {
    margin-bottom: 5px;
  }
  .shop-group-item ul li {
    border-bottom: 1px solid @backgroundGrey;
  }
  .shop-group-item ul:last-child li:last-child {
    border-bottom: none;
  }

  .shop-name {
    background: @textWhite;
    height: 35px;
    line-height: 35px;
    .px2rem(padding-left, 15);
    .px2rem(padding-right, 15);
    position: relative;
    color: #282828;
  }
  .shop-name h4 {
    a {
      .px2rem(font-size, 18);
    }
    float: left;
    background: url("../images/icon-kin.png") no-repeat left center;
    line-height: 35px;
    .px2rem(background-size, 20);
    .px2rem(margin-left, 15);
    padding-left: 25px;
  }
  .shop-info {
    background: #fff;
    height: 120px;
    //.px2rem(padding-left,15);
    //.px2rem(padding-right,15);
    position: relative;
  }
  .shop-info .shop-info-img {
    position: absolute;
    top: 15px;
    left: 15px;
    .px2rem(width, 85);
    .px2rem(height, 85);
  }
  .shop-info .shop-info-img img {
    width: 100%;
    height: 100%;
  }
  .shop-info .shop-info-text {
    margin-left: 100px;
    padding: 15px 0;
  }
  .shop-info .shop-info-text h4 {
    border: 1px transparent solid;
    font-weight: normal;
    color: @textBlack;
    .px2rem(font-size, 16);
    .px2rem(line-height, 20);
    .px2rem(paddint-right, 20);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .shop-info .shop-info-text .shop-brief {
    height: 25px;
    line-height: 25px;
    .px2rem(font-size, 14);
    color: #81838e;
    white-space: nowrap;
  }
  .shop-info .shop-info-text .shop-brief span {
    display: inline-block;
    margin-right: 8px;
  }
  .shop-info .shop-info-text .shop-price {
    height: 24px;
    line-height: 24px;
    position: relative;
  }
  .shop-info .shop-info-text .shop-price .shop-pices {
    color: #ea5341;
    font-size: 16px;
  }
  .shop-info .shop-info-text .shop-arithmetic {
    position: absolute;
    right: 0px;
    top: 0;
    width: 84px;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap;
    height: 100%;
  }
  .shop-info .shop-info-text .shop-arithmetic .num {
    width: 32px;
    text-align: center;
    display: inline-block;
    height: 100%;
    .px2rem(font-size, 20);
    color: #939393;
    box-sizing: border-box;
    vertical-align: top;
    margin: 0 -6px;
  }
  .payment-bar {
    .borderTopNormal;
    clear: both;
    overflow: hidden;
    width: 100vw;
    height: 4.16rem;
    position: fixed;
    bottom: 0;
    background: #fff;
  }
  .payment-bar .shop-total {
    float: right;
    height: 4.16rem;
    line-height: 4.16rem;
    .px2rem(margin-right, 35);
    .px2rem(font-size, 20);
    -webkit-box-flex: 1.0;
    box-flex: 1.0;
  }
  .payment-bar .total {
    color: #ea5341;
    .numberBig;
  }
  .payment-bar .shop-total strong {
    color: @textBlack;
    display: block;
    .textNormal;
  }
  .payment-bar .settlement {
    display: inline-block;
    float: right;
    height: 4.16rem;
    line-height: 4.16rem;
    width: 25%;
    .px2rem(font-size, 20);
    text-align: center;
    color: #fff !important;
    background: url("../images/chartBtn.png");
  }
}

//商品详情
#goodsDetail {
  background: @backgroundGrey;
  height: 100%;
  overflow-y: scroll;
  //padding-bottom: 4.16rem;
  .detailHead {
    .px2rem(min-height, 200);
    max-height: 60vh;
    background: #fff;
    .goback {
      display: block;
      .px2rem(height, 38);
      .px2rem(width, 38);
      position: absolute;
      .px2rem(left, 15);
      .px2rem(top, 15);
      img {
        width: 100%;
      }
    }
    position: relative;
    display: inline-block;
    text-align: center;
    img {
      display: block;
      //height: 100%;
      width: 100%;
      margin: 0 auto;
    }
    ul[rn-carousel] {
      width: 100vw;
      height: 100%;
      margin: 0 auto;
      float: left;
    }
    .custom-indicator {
      position: absolute;
      bottom: 0;
      width: 100%;
      span {
        transition: all 0.3s ease-in;
        color: gray;
        .px2rem(font-size, 10);
        .px2rem(margin-right, 8);
      }
      span.active {
        color: @textRed !important;
      }
    }
  }
  .money {
    color: @textRed;
    .numberBig;
  }
  .discountedPrice {
    color: @textGrey;
    .px2rem(font-size, 14);
    text-decoration: line-through;
    .px2rem(padding-left, 10);
  }
  .stock {
    float: right;
    color: @textGrey;
    .textSmall;
  }
  .goodsInfo {
    background: @textWhite;
    .discountedPrice {
      color: @textGrey;
      .px2rem(font-size, 16);
      text-decoration: line-through;
    }
    > div {
      font-family: PingFang-SC-Medium;
      .bigBottom;
      .px2rem(padding, 15);
      color: @textBlack;
      .textBig;
      span {
        .textBig;
      }
    }
    .goodsName {
      .textBig;
      .px2rem(margin-bottom, 10);
    }
    > p {
      .px2rem(padding-right, 15);
      .textBig;
      .px2rem(line-height, 34);
      span {
        .numberBig;
        color: @textRed;
      }
      .stock {
        float: right;
        color: @textGrey;
        .numberSmall;
      }
    }
  }
;
  .description {
    .descriptionTag {
      .px2rem(margin-bottom, 15);
    }
    .descriptionTag > span {
      border: 1px @borderGrey solid;
      display: inline-block;
      width: 50%;
      text-align: center;
      .px2rem(padding, 10);
      position: relative;
      > i {
        display: none;
        border: 1px #e75446 solid;
        position: absolute;
        bottom: -1px;
        left: -1px;
        width: calc(100% + 1px);
      }
    }
    .descriptionTag > span:last-child {
      border-left: none;
    }
    .descriptionTag > span.hover {
      background: @backgroundGrey;
    }
    .descriptionTag > span.hover > i {
      display: block;
    }
    .descriptionDetail {
      max-width: 100vw;
      overflow-x: scroll;
      .px2rem(padding, 15);
      table {
        max-width: 100%;
      }
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
      }
    }
    .descriptionSku {
      > ul {
        border: 1px @borderGrey solid;
        margin-top: -1px;
      }
      li.fistOne {
        border-top-color: transparent;
        position: relative;
        > i {
          border-left: 2px #e75446 solid;
          height: calc(100% + 2px);
          position: absolute;
          left: 0;
          top: -1px;
        }
      }
      li {
        border-top: 1px @borderGrey solid;
        .px2rem(padding-left, 15);
        .px2rem(padding-right, 15);
        .px2rem(line-height, 40);
      }
    }
    .px2rem(font-size, 18) !important;
    color: @textGrey;
  }
}

.goodsDetailfooter {
  background: #fff;
  .borderTopNormal;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 4.16rem;
  display: flex;
  flex-direction: row;
  .goodsDetailfooter_l{
    width: 40%;
    display: flex;
    flex-direction: row;
  }
  .goodsDetailfooter_r{
    width: 60%;
    display: flex;
    flex-direction: row;
  }
  span {
    padding: 0.69333333rem;
    display: block;
    float: left;
    text-align: center;
    width: 50%;
    height: 4.16rem;
  }
  span img {
    height: 1.248rem;
    display: block;
    margin: 0 auto;
    .px2rem(margin-bottom, 6);
  }
  .addChar ,.buyNow {
    border: none;
    width: 50%;
    height: 4.16rem;
    color: #ffffff;
    text-align: center;
    line-height: 4.16rem;
    .textBig;
  }
  .buyNow {
    background: url("../images/buyNow.png");
  }
  .addChar {
    background: url("../images/addChart.png");
  }
}

//添加地址
#addAddress {
  background: @backgroundGrey;
  height: 100vh;
  //background: blue;
  .select1 {
    border: none !important;
    .px2rem(padding-top, 15) !important;
    form {
      border: none;
      .px2rem(padding-left, 15);
      .px2rem(padding-right, 15);
    }
  }
  .list {
    .item {
      border-color: @backgroundGrey;
      background: @textWhite;
    }
    .right {
      position: absolute;
      .px2rem(right, 15);
      border: none;
    }
    textarea {
      .px2rem(padding, 15);
      width: 100vw;
      .px2rem(max-height, 100);
      resize: none;
      border-bottom: 1px @backgroundGrey solid;
      border-top: 1px @backgroundGrey solid;
    }
    label:hover {
      background: transparent !important;
    }
    label {
      position: relative;
      .check1 {
        display: inline-block;
        .px2rem(width, 23) !important;
        .px2rem(height, 23) !important;
        .px2rem(margin-right, 10);
        background: url("../images/icon_radio3.png") no-repeat center;
        background-size: 1.52533333rem 1.52533333rem;
        outline: none !important;
      }
      .check1.hover {
        background: url("../images/icon_radio4.png") no-repeat;
        background-size: 1.52533333rem 1.52533333rem;
      }
    }
  }
}

//选择地址
#chooseAddress {
  .item {
    border-color: @backgroundGrey;
  }
  .list {
    overflow-y: scroll;
    p {
      .textNormal;
      color: @textBlack;
      span {
        color: @textBlack;
        .textNormal;
      }
      span.right {
        .textNormal;
        color: @textGrey;
      }
    }
    .default {
      color: @textRed;
    }
  }
}

//管理收货地址
#manageAddress {
  .list {
    overflow-y: scroll;
    background: @backgroundGrey;
    .item {
      border: none;
      padding: 0;
      .px2rem(padding-top, 15);
      > p {
        .px2rem(padding-left, 15);
        .px2rem(padding-right, 15);
        .px2rem(line-height, 30);
      }
    }
    .item1 {
      .borderTopNormal;
      .px2rem(font-size, 20);
      .px2rem(line-height, 50);
      //.px2rem(margin-top, 20);
      .px2rem(padding, 15);
      //.px2rem(padding-right, 15);
      color: @textRed;
      border-bottom: @backgroundGrey solid;
      .px2rem(border-bottom-width, 10);
      span {
        .textNormal;
      }
    }
    p {
      .px2rem(font-size, 20);
      .px2rem(line-height, 30);
      color: @textBlack;
      span {
        color: @textGrey;
        .px2rem(font-size, 20);
      }
    }
    span.right {
      i {
        display: inline-block;
        .px2rem(font-size, 26);
        .px2rem(padding-right, 6);
      }
      span {
        display: inline-block;
        .px2rem(font-size, 20);
        .px2rem(margin-left, 10);
      }
      color: @textBlack;
    }

  }
  .check {
    display: inline-block;
    vertical-align: text-bottom;
    .px2rem(width, 23);
    .px2rem(height, 23);
    .px2rem(margin-right, 10);
    background: url("../images/icon_radio3.png") no-repeat center;
    background-size: 1.52533333rem 1.52533333rem;
  }
  .check.hover {
    background: url("../images/icon_radio4.png") no-repeat center;
    background-size: 1.52533333rem 1.52533333rem;
  }
  .addAddress {
    .borderTopNormal;
    z-index: 2;
    position: fixed;
    width: 100vw;
    color: @textRed;
    height: 4.16rem;
    line-height: 4.16rem;
    //.px2rem(height,50);
    //.px2rem(line-height,50);
    .px2rem(font-size, 20);
    text-align: center;
    bottom: 0;
    left: 0;
    background: @textWhite;
  }
}

//编辑地址
#editAddress {
  .item {
    border-color: @backgroundGrey;
  }
  .select1 {
    border: none !important;
    .px2rem(padding-top, 15) !important;
    form {
      border: none;
      .px2rem(padding-left, 15);
      .px2rem(padding-right, 15);
    }
  }
  textarea {
    .px2rem(padding, 15);
    width: 100vw;
    .px2rem(max-height, 100);
    resize: none;
    border-bottom: 1px @backgroundGrey solid !important;
    border-top: 1px @backgroundGrey solid;
  }
  .right {
    display: block;
    position: absolute;
    .px2rem(right, 15);
    .px2rem(line-height, 50);
    border: none;
  }
}

//订单成功的
#successfully {
  .main {
    .px2rem(padding, 15);
    .px2rem(line-height, 30);
    p > span {
      .px2rem(margin-left, 10);
    }
    p > button {
      float: right;
      .px2rem(margin-right, 10);
      border: 1px @borderGrey solid;
      background: @backgroundWhite;
      //background: url("../images/successbtn.png") no-repeat;
      //background-size: 7.76533333rem 2.7733333rem ;
      .px2rem(height, 42);
      .px2rem(width, 112);
      .px2rem(border-radius, 6);
    }
    .red {
      color: @textRed;
    }
    .address {
      font-size: 80%;
      color: @textGrey;
    }
  }
  .widthBottom {
    border-bottom: 15px @backgroundGrey solid;
  }
  .companyMsg {
    border-top: 1px @borderGrey solid;
    .px2rem(padding, 20);
    > p {
      text-align: center;
      > span {
        color: @textRed;
        .px2rem(padding-left, 10);
      }
    }
    .textMain {
      border: 1px @borderGrey solid;
      .px2rem(border-radius, 6);
      .px2rem(padding-left, 15);
      .px2rem(padding-right, 15);
      .px2rem(padding-top, 20);
      .px2rem(margin-top, 20);
      box-shadow: 2px 2px 1px 1px #7e7e7e;
      div {
        .px2rem(line-height, 20);
        display: table;
        border-bottom: 1px @borderGrey solid;
        > span {
          .px2rem(width, 100);
          .px2rem(height, 30);
          .px2rem(line-height, 30);
          .textBig;
          display: block;
          i {
            color: @textRed;
          }
        }
        > p {
          display: table-cell;
          .px2rem(height, 30);
          width: 100%;
          position: relative;
        }

        input {
          width: 100%;
          .px2rem(height, 30);
          position: absolute;
        }
      }
      > button {
        width: 100%;
        .textBig;
        background: #e75446;
        border: none;
        .px2rem(border-radius, 6);
        .px2rem(height, 50);
        .px2rem(line-height, 50);
        .px2rem(margin-bottom, 50);
        .px2rem(margin-top, 50);
        color: @textWhite;
      }
      > button.disable {
        background: rgba(245, 83, 69, 0.71);
      }
    }
  }
}

//商品列表
.shopping {
  background: #F5F5F5;
  clear: both;
  overflow: scroll;
  padding-bottom: 60px;
  .shop-group-item {
    margin-bottom: 5px;
  }
  .shop-group-item ul li {
    border-bottom: 1px solid #989898;
  }
  .shop-group-item ul:last-child li:last-child {
    border-bottom: none;
  }

  .shop-name {
    height: 35px;
    line-height: 35px;
    .px2rem(padding-left, 15);
    .px2rem(padding-right, 15);
    position: relative;
    color: #282828;
    span {
      color: @textRed;
      float: right;
    }
  }
  .shop-name h4 {
    a {
      .px2rem(font-size, 18);
    }
    float: left;
    background: url("../images/icon-kin.png") no-repeat left center;
    line-height: 35px;
    .px2rem(background-size, 20);
    .px2rem(margin-left, 15);
    padding-left: 25px;
  }
  .shop-info {
    background: #f5f5f5;
    height: 120px;
    //.px2rem(padding-left,15);
    //.px2rem(padding-right,15);
    position: relative;
  }
  .shop-info .shop-info-img {
    position: absolute;
    top: 15px;
    left: 15px;
    .px2rem(width, 85);
    .px2rem(height, 85);
  }
  .shop-info .shop-info-img img {
    width: 100%;
    height: 100%;
  }
  .shop-info .shop-info-text {
    margin-left: 100px;
    padding: 15px 0;
  }
  .shop-info .shop-info-text h4 {
    border: 1px transparent solid;
    font-weight: normal;
    color: #282828;
    .px2rem(font-size, 16);
    .px2rem(line-height, 20);
    .px2rem(paddint-right, 20);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .shop-info .shop-info-text .shop-brief {
    height: 25px;
    line-height: 25px;
    .px2rem(font-size, 14);
    color: #81838e;
    white-space: nowrap;
  }
  .shop-info .shop-info-text .shop-brief span {
    display: inline-block;
    margin-right: 8px;
  }
  .shop-info .shop-info-text .shop-price {
    height: 24px;
    line-height: 24px;
    position: relative;
  }
  .shop-info .shop-info-text .shop-price .shop-pices {
    color: #ea5341;
    font-size: 16px;
  }
  .shop-info .shop-info-text .shop-arithmetic {
    position: absolute;
    right: 0px;
    top: 0;
    width: 84px;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap;
    height: 100%;
  }
  .shop-info .shop-info-text .shop-arithmetic .num {
    width: 32px;
    text-align: center;
    display: inline-block;
    height: 100%;
    .px2rem(font-size, 20);
    color: #939393;
    box-sizing: border-box;
    vertical-align: top;
    margin: 0 -6px;
  }
  .shopPrice {
    background: #fff;
    height: 35px;
    line-height: 35px;
    padding: 0 15px;
    text-align: right;
  }
  .cancel {
    padding: 0 15px;
    text-align: right;
    button {
      background: @backgroundWhite;
      .px2rem(height, 40);
      .px2rem(width, 110);
      border: 1px @borderGrey solid;
      .px2rem(border-radius, 6);
    }
  }
  .shopPrice span {
    font-size: 120%;
  }
}

//全部订单标签
#myOrder {
  .tag {
    .px2rem(padding, 15);
    li {
      float: left;
      width: 25%;
      text-align: center;
      .px2rem(line-height, 42);
    }
    li.active {
      color: @textRed;
      border-bottom: 1px @textRed solid;
    }

  }
  .content {
    overflow-y: scroll;
  }
}

//订单详情
#orderDetail {
  .status {
    .px2rem(padding, 15);
    color: @textRed;
    font-size: 11%;
    background: @backgroundWhite;
    .px2rem(margin-bottom, 10);
  }
  .userMsg {
    background: @backgroundWhite;
    width: 100%;
    height: 5.54666667rem;
    .px2rem(padding, 15);
    color: @textGrey;
    .px2rem(margin-bottom, 10);
    display: table;
    > span {
      background: @backgroundWhite;
      display: block;
      height: 100%;
      .px2rem(font-size, 20);
      .px2rem(width, 50);
      text-align: center;
      i {
        line-height: 40px;
        .px2rem(font-size, 30);
      }
      //.px2rem(padding-left,10);
    }
    > div {
      display: table-cell;
      width: 100%;
      vertical-align: middle;
      .userName {
        .px2rem(font-size, 18);
        > span {
          .px2rem(font-size, 18);
        }
      }
    }
    p {
      .px2rem(line-height, 30);
      .px2rem(font-size, 20);
    }
  }
  .orderNo {
    .px2rem(padding, 15);
    .px2rem(padding-top, 10);
    .px2rem(padding-bottom, 10);
    > span {
      float: right;
    }
  }
  /*  .userMsg {
      >span {
        position: absolute;
        .px2rem(left,-40);
        .px2rem(top,22)
      }
    }*/
  .export {
    padding: 20px;
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background: white;
    height: 73px;
  }
}

//加载页面
#load {
  position: absolute;
  left: 0;
  top: calc(50% - 10.2rem);
  z-index: 100;
  .px2rem(height, 150);
  width: 100%;
  > div {
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.69);
    align-items: center;
    .px2rem(padding-top, 40);
    .px2rem(border-radius, 10);
    .px2rem(width, 150);
    .px2rem(height, 150);
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 25%;
    justify-content: center;
    > p {
      color: #fff;
      .px2rem(margin-top, 40);
      .px2rem(font-size, 20);
    }
    .line-spin-fade-loader {
      position: relative;
      top: -10px;
      left: -4px;
    }

    .line-spin-fade-loader > div:nth-child(1) {
      top: 20px;
      left: 0;
      -webkit-animation: line-spin-fade-loader 1.2s -0.84s infinite ease-in-out;
      animation: line-spin-fade-loader 1.2s -0.84s infinite ease-in-out;
    }

    .line-spin-fade-loader > div:nth-child(2) {
      top: 13.63636px;
      left: 13.63636px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-animation: line-spin-fade-loader 1.2s -0.72s infinite ease-in-out;
      animation: line-spin-fade-loader 1.2s -0.72s infinite ease-in-out;
    }

    .line-spin-fade-loader > div:nth-child(3) {
      top: 0;
      left: 20px;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      -webkit-animation: line-spin-fade-loader 1.2s -0.6s infinite ease-in-out;
      animation: line-spin-fade-loader 1.2s -0.6s infinite ease-in-out;
    }

    .line-spin-fade-loader > div:nth-child(4) {
      top: -13.63636px;
      left: 13.63636px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-animation: line-spin-fade-loader 1.2s -0.48s infinite ease-in-out;
      animation: line-spin-fade-loader 1.2s -0.48s infinite ease-in-out;
    }

    .line-spin-fade-loader > div:nth-child(5) {
      top: -20px;
      left: 0;
      -webkit-animation: line-spin-fade-loader 1.2s -0.36s infinite ease-in-out;
      animation: line-spin-fade-loader 1.2s -0.36s infinite ease-in-out;
    }

    .line-spin-fade-loader > div:nth-child(6) {
      top: -13.63636px;
      left: -13.63636px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-animation: line-spin-fade-loader 1.2s -0.24s infinite ease-in-out;
      animation: line-spin-fade-loader 1.2s -0.24s infinite ease-in-out;
    }

    .line-spin-fade-loader > div:nth-child(7) {
      top: 0;
      left: -20px;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      -webkit-animation: line-spin-fade-loader 1.2s -0.12s infinite ease-in-out;
      animation: line-spin-fade-loader 1.2s -0.12s infinite ease-in-out;
    }

    .line-spin-fade-loader > div:nth-child(8) {
      top: 13.63636px;
      left: -13.63636px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out;
      animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out;
    }

    .line-spin-fade-loader > div {
      background-color: #fff;
      border-radius: 2px;
      margin: 2px;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      position: absolute;
      width: 5px;
      height: 15px;
    }
    @-webkit-keyframes line-spin-fade-loader {
      50% {
        opacity: 0.3;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes line-spin-fade-loader {
      50% {
        opacity: 0.3;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

//全部订单
#allOrder {
  background: #F5F5F5;
}

.shop-name {
  background: white;
}

.shop-group-item {
  background: white;
  margin-top: 5px;
  padding-bottom: 10px;
}

.confimorder {
  padding: 0 15px;
  text-align: right;
  button {
    //background: url("../images/successbtn.png");
    //background-size: cover;
    .px2rem(height, 40);
    .px2rem(width, 110);
    .px2rem(border-radius, 6);
    border: 1px @borderGrey solid;
    background: @backgroundWhite;
  }
}

.shopping .shopdetailPrice {
  margin-left: 15px;
  margin-bottom: 73px;
  .px2rem(padding-top, 20);
  overflow-y: auto;
}

.noorderimg {
  .px2rem(margin-top, 200);
  img {
    display: block;
    .px2rem(height, 170);
    margin: 0 auto;
  }
  > p {
    text-align: center;
    .px2rem(margin-top, 20);
    color: @textGrey;
    .textBig;
  }
  > i {
    text-align: center;
    display: block;
    color: @textRed;
    .textBig;
    .px2rem(margin-top, 10);
  }
}

//输入手机号或者店铺客户确认
#addPhoneModal {
  z-index: 11;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  bottom: 0;
  > div {
    z-index: 12;
    position: absolute;
    bottom: 0;
    left: 0;
    .px2rem(height, 450);
    width: 100vw;
    background: #f5f5f5;
    .close1 {
      display: block;
      position: absolute;
      background: url("../images/phoneClose.png") no-repeat;
      background-size: cover;
      .px2rem(right, 10);
      .px2rem(top, 10);
      .px2rem(height, 25);
      .px2rem(width, 25);
    }
    .topImg {
      .px2rem(height, 117);
      position: relative;
      border: 1px transparent solid;
      img {
        display: block;
        .px2rem(height, 162);
        .px2rem(margin-top, -81);
        margin-left: auto;
        margin-right: auto;
      }
    }
    .loginBtn {
      .px2rem(height, 50);
      .px2rem(border-radius, 6);
      .px2rem(margin-top, 65);
      text-align: center;
      .px2rem(line-height, 50);
      .px2rem(font-size, 20);
      background: #e75446;
      color: @textWhite;
    }
    .loginBtn.disable {
      background: rgba(245, 83, 69, 0.71);
    }
  }
  > div.addPhone {
    .bottomText {
      //border: 1px red solid;
      .px2rem(padding-left, 85);
      .px2rem(padding-right, 85);
      > p {
        border-bottom: 1px @borderGrey solid;
        position: relative;
        .px2rem(padding-top, 15);
        .px2rem(padding-bottom, 0);
        input {
          width: 100%;
          .textBig;
          background: transparent;
          border: none;
        }
        span, i {
          position: absolute;
          border-left: 1px @textRed solid;
          right: 0;
          .px2rem(bottom, 5);
          .px2rem(line-height, 40);
          .px2rem(padding-left, 20);
          .px2rem(padding-right, 10);
          .px2rem(height, 40);
          .textBig;
          color: @textRed;
        }
        i {
          .px2rem(width, 121);
          text-align: center;
        }
      }
    }
  }
  > div.bindShop {
    .topImg {
      img {
        .px2rem(width, 162);
        box-sizing: border-box;
        .px2rem(border, 6);
        border-color: @textWhite;
        border-style: solid;
        box-shadow: 1px 2px 2px 2px rgba(122, 122, 122, 0.45);
      }
    }
    .bottomText {
      .px2rem(padding-left, 70);
      .px2rem(padding-right, 70);
      .shop {
        background: url("../images/bindshop1.png") no-repeat;
        background-size: 35px 35px;
        background-position: left center;
        line-height: 45px;
        .px2rem(padding-left, 90);
        .textBig;
      }
      .person {
        background: url("../images/bindshop2.png") no-repeat;
        background-size: 35px 35px;
        background-position: left center;
        line-height: 45px;
        .px2rem(padding-left, 90);
        .textBig;
      }
      .pho {
        background: url("../images/bindshop3.png") no-repeat;
        background-size: 35px 35px;
        background-position: left center;
        line-height: 45px;
        .px2rem(padding-left, 90);
        .textBig;
      }
    }
  }
}

//登录窗口
#loginModal {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
  > div {
    z-index: 12;
    position: absolute;
    bottom: 0;
    left: 0;
    .px2rem(height, 450);
    width: 100vw;
    background: #f5f5f5;
    .close1 {
      display: block;
      position: absolute;
      background: url("../images/phoneClose.png") no-repeat;
      background-size: cover;
      .px2rem(right, 10);
      .px2rem(top, 10);
      .px2rem(height, 25);
      .px2rem(width, 25);
    }
    .topImg {
      .px2rem(height, 117);
      position: relative;
      border: 1px transparent solid;
      img {
        display: block;
        .px2rem(height, 162);
        .px2rem(margin-top, -81);
        margin-left: auto;
        margin-right: auto;
      }
    }
    .loginBtn {
      .px2rem(height, 50);
      .px2rem(border-radius, 6);
      .px2rem(margin-top, 65);
      text-align: center;
      .px2rem(line-height, 50);
      .px2rem(font-size, 20);
      background: #e75446;
      color: @textWhite;
    }
  }
  .QQlogin {

    .bottomText {
      position: absolute;
      left: 0;
      width: 100vw;
      transition: left 0.2s linear;
      .px2rem(padding-left, 85);
      .px2rem(padding-right, 85);
      > p {
        border-bottom: 1px @borderGrey solid;
        position: relative;
        .px2rem(padding-top, 15);
        .px2rem(padding-bottom, 0);
        input {
          width: 100%;
          .textBig;
          background: transparent;
          border: none;
        }
        span, i {
          position: absolute;
          border-left: 1px @textRed solid;
          right: 0;
          .px2rem(bottom, 10);
          .px2rem(line-height, 30);
          .px2rem(padding-left, 20);
          .px2rem(padding-right, 10);
          .px2rem(height, 30);
          .textBig;
          color: @textRed;
        }
        i {
          .px2rem(width, 121);
          text-align: center;
        }
        i.lookPass {
          border: none;
          display: block;
          position: absolute;
          .px2rem(height, 20);
          .px2rem(width, 40);
          padding: 0;
          //.px2rem(height,20);
          line-height: normal;
          text-align: center;
          img {
            height: 100%;
          }
        }
      }
      > span {
        display: block;
        text-align: center;
        color: @textGrey;
        .px2rem(line-height, 50);
        .textBig;
      }
    }
    .bottomText.setPass {
      background: #f5f5f5;
      left: 100vw;
    }
    .bottomText.setCode {
      background: #f5f5f5;
      left: -100vw;
    }
    .loginBtn.disable {
      background: rgba(245, 83, 69, 0.71);
    }
  }
  .QQbindshop {
    .topImg {
      img {
        .px2rem(width, 162);
        box-sizing: border-box;
        .px2rem(border, 6);
        border-color: @textWhite;
        border-style: solid;
        box-shadow: 1px 2px 2px 2px rgba(122, 122, 122, 0.45);
      }
    }
    .bottomText {
      .px2rem(padding-left, 70);
      .px2rem(padding-right, 70);
      .shop {
        background: url("../images/bindshop1.png") no-repeat;
        background-size: 35px 35px;
        background-position: left center;
        line-height: 45px;
        .px2rem(padding-left, 90);
        .textBig;
      }
      .person {
        background: url("../images/bindshop2.png") no-repeat;
        background-size: 35px 35px;
        background-position: left center;
        line-height: 45px;
        .px2rem(padding-left, 90);
        .textBig;
      }
      .pho {
        background: url("../images/bindshop3.png") no-repeat;
        background-size: 35px 35px;
        background-position: left center;
        line-height: 45px;
        .px2rem(padding-left, 90);
        .textBig;
      }
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 1111;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 350px) {
  .bottomText {
    padding-left: 3.46666667rem !important;
    padding-right: 3.46666667rem !important;
    //.px2rem(padding-left,50)!important;
    //.px2rem(padding-right,50)!important;;
  }

}

@media screen and (min-height: 723px) and (device-width: 375px) and
(-webkit-device-pixel-ratio: 3) {
  .goodsDetailfooter {
    height: 6.16rem!important;
  }

  .goodsDetail {
    padding-bottom: 6.16rem!important;
  }
  .showD>div .num{
    bottom: 6.09066667rem;
  }
  .showD>div .sure{
    bottom: 2rem!important;
  }
  #confirmOrder .payment-bar{
    bottom: 2rem!important;
  }
  #manageAddress .addAddress{
    bottom: 2rem!important;
  }
  #footer{
    bottom: 2rem!important;
  }
  #chart .payment-bar{
    bottom: 6.85333rem!important;
  }
}



